<template>
  <div>
    <div ref="map" class="map"></div>
  </div>
</template>

<script>
import searchFrame from "@/components/searchComponents/searchFrame";
import 'ol/ol.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {Point, LineString} from 'ol/geom';
import {Style, Stroke, Circle, Fill, Icon} from 'ol/style';
import Feature from 'ol/Feature';
import {fromLonLat} from 'ol/proj';

export default {
  name: 'mapLayer',
  data() {
    return {
      map: null,
      trackData: [
        {latitude: 48.8566, longitude: 2.3522}, // 巴黎
        {latitude: 51.5074, longitude: -0.1278}, // 伦敦
        {latitude: 40.7128, longitude: -74.0060}, // 纽约
        {latitude: 35.6895, longitude: 139.6917}, // 东京
        {latitude: -33.8688, longitude: 151.2093}, // 悉尼
      ],
    };
  },
  mounted() {
    this.initMap();
    this.addTrack();
  },
  components: {
    searchFrame,
  },
  methods: {
    initMap() {
      // Leaflet 地图初始化
      this.map = L.map(this.$refs.map, {
        attributionControl: false,
        zoomControl: false,  // 禁用放大缩小控件
        maxBounds: [
          [-90, -Infinity],  // 南极和无限制的西经度
          [90, Infinity]     // 北极和无限制的东经度
        ],
        minZoom: 2
      }).setView([0, 0], 2);

      // 使用 Leaflet 加载 Mapbox 地图
      L.tileLayer(`https://api.mapbox.com/styles/v1/ohx/clil33vcn00g501pf022z1cme/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib2h4IiwiYSI6ImNsaWwybmV1ODAxdzYzcms5Z3pvNzhvdTIifQ.YpFzvJXs5wJn5JVQcOc3cg`, {
        maxZoom: 18,
        tileSize: 512,
        zoomOffset: -1,
      }).addTo(this.map);
    },

  },
};
</script>

<style scoped>
.map {
  height: 93.6vh;
  width: 98.7vw;
}
</style>
