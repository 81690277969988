<template>
  <mapLayer></mapLayer>
</template>

<script>
import mapLayer from "@/components/mapLayer";
export default {
  name: "test",
  components: {
    mapLayer, // 注册 TopSection 组件
  },
}
</script>

<style scoped>

</style>
